<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>기술 문의</h3>
      </div>
      <div class="my-3 card">
        <mdb-row>
          <mdb-col></mdb-col>
          <mdb-col>
            <mdb-select v-model="basicOptions" placeholder="-검색선택-" @getValue="getSelectValue"/>
          </mdb-col>
          <mdb-col>
            <mdb-input v-model="search" placeholder="검색어를 입력하세요."/>
          </mdb-col>
          <mdb-col></mdb-col>
        </mdb-row>
      </div>
      <p class="mt-4 mb-1">총 게시물 <strong style="color: red">{{ totCount }}</strong> 건</p>
      <mdb-datatable-2
          class="mb-3 card"
          v-model="data"
          hover
          :searching="{value: search, field: fieldName} "
          @selected="onSelected($event)"
          noFoundMessage="데이터가 없습니다."/>
    </mdb-col>
    <mdb-col class="mb-5 text-right" style="text-align: center">
    </mdb-col>
  </mdb-container>
</template>

<script>
import {mdbCol, mdbRow, mdbSelect, mdbContainer, mdbDatatable2, mdbInput} from 'mdbvue';
import firebase from "firebase/app";
import technical_columns from "../../../../assets/technical_columns";

export default {
  name: 'TechnicalList',
  components: {
    mdbContainer,
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbDatatable2,
    mdbInput,
  },
  data() {
    return {
      basicOptions: [
        {text: "제목", value: "title"},
        {text: "분야", value: "fields"},
      ],
      rowData: [],
      selected: null,
      search: '',
      data: {
        columns: technical_columns,
        rows: []
      },
      fieldName: 'title',
      totCount: 0,
      fields: '',
    }
  },
  mounted() {
    this.onFirebaseGetAllArrayData();
  },
  methods: {
    onSelected(value) {
      this.selected = value;
      const self = this;
      self.$router.push({name: 'adminTechnicalManageView', query: {id: value.id}}).catch((err) => {
        console.log(err)
      });
    },
    getSelectValue(value) {
      // console.log(value);
      this.fieldName = value
    },
    onFirebaseGetAllArrayData() {
      const db = firebase.firestore();
      const self = this;
      let counter = 0;
      db.collection("technicalBoard").orderBy("reg_date", "desc").get()
          .then((querySnapshot) => {
            self.totCount = querySnapshot.size;
            counter = self.totCount;
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              // console.log(_data);
              _data.order_number = counter;
              counter--;
              _data["id"] = doc.id;
              const date = new Date(_data.reg_date.seconds * 1000);
              _data.reg_date = self.getParseDate(date);
              self.data.rows.push(_data);
              // console.log(self.data.rows);
            });
          })
          .catch(function (e) {
            console.log(e);
          });
    },
    getParseDate(date) {
      return date.getFullYear() +
          "-" + (date.getMonth() + 1) +
          "-" + date.getDate() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds()
    },
    goWriteForm() {
      const self = this;
      if (self.$store.state.login) {
        self.$router.push('/technicalForm');
      } else {
        alert('로그인이 필요합니다.')
        self.$router.push('/user/login');
      }
    }
  }
}
</script>

<style scoped>

.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}

</style>